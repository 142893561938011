<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-bonuspoints" /> 我收的
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <a-row :gutter="[16, 16]" class="waybill-list">
              <a-col :span="12" v-for="(item, index) in list" :key="index">
                <a-card :title="`运单号：${item.number}`" :hoverable="true">
                  <router-link slot="extra" :to="`/WaybillDetail?id=${item.number}`">运单详情
                    <a-icon type="right" />
                  </router-link>
                  <div class="waybill-info">
                    <div class="post-city-user">
                      <div class="city">
                        {{ item.senderProvinceName }}
                      </div>
                      <div class="user">
                        {{ item.senderName }}
                      </div>
                    </div>
                    <div class="state">
                      {{ item.waybillStateName }}
                    </div>
                    <div class="receive-city-user">
                      <div class="city">
                        {{ item.receiverProvinceName }}
                      </div>
                      <div class="user">
                        {{ item.receiverName }}
                      </div>
                    </div>
                  </div>
                  <div class="state">
                    {{ item.waybillStateName }}：{{ item.waybillStateName }}
                  </div>
                  <div class="time u-flex" style="border-top: 1px solid #e4e7ed">
                    <div class="timeitem">{{item.waybillStateName}}时间：{{item.waybillStateTime}}</div>
                    <div class="price">运费：{{item.payAmount}}</div>
                  </div>
                </a-card>
              </a-col>
              <div v-if="list.length == 0" syle="padding-top: 20px">
                <a-empty description="暂无数据" />
              </div>
            </a-row>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
  import Foot from "@/components/foot";
  import Nav from "@/components/Nav.vue";
  import Menu from "../user/components/menu";
  import {
    Icon
  } from "ant-design-vue";
  const IconFont = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
  });
  export default {
    components: {
      Nav,
      Foot,
      Menu,
      IconFont,
    },
    data() {
      return {
        user: {},
        list: [],
      };
    },
    computed: {},
    created() {
      if (this.$route.name === "Home") this.main_swiper = true;
    },
    watch: {
      // 如果路由有变化，会再次执行该方法
      $route: "fetchDate",
    },
    mounted() {
      this.user = this.storage.getItem("user");
      if (!this.user) {
        this.getUserInfo();
      }
      this.api
        .getmywaybill()
        .then((res) => {
          this.list = res.reveive;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    methods: {
      fetchDate() {
        if (this.$route.name !== "Home") {
          this.main_swiper = false;
        } else {
          this.main_swiper = true;
        }
      },
      getUserInfo() {
        this.api
          .getuserDetail({})
          .then((res) => {
            this.storage.setItem("user", res);
            this.user =  res;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>


<style lang="less">
  @import "~ant-design-vue/dist/antd.less";

  .page {
    background: #f5f5f5;
  }

  .wrap {
    height: 100%;

    // padding-bottom: 20px;
  }

  .ant-layout {
    height: 100%;
  }

  .ant-layout-sider,
  .ant-layout-content,
  .ant-layout-header,
  .ant-layout-footer {
    background: #ffffff !important;
    padding: 10px;
  }

  .ant-layout-sider {
    margin-right: 10px;
    text-align: center;
  }

  .ant-layout-content {
    margin-top: 10px;
  }

  .ant-layout-header {
    height: auto;

    .top {
      .ant-col {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  .user {
    .user-name {
      font-size: 14px;
      margin-top: 10px;
    }

    .other-info {
      text-align: left;
      margin-left: 30px;

      span {
        color: @text-color-secondary;
      }
    }
  }

  .menu {
    text-align: left;
    padding-left: 30px;
    line-height: 28px;
    margin-top: 30px;
    border-top: 1px solid #e2e2e2;

    dl {
      padding-top: 10px;

      dt {
        font-size: 16px;
        line-height: 28px;
      }

      dd {
        color: @text-color-secondary;
      }
    }
  }

  div.content-wrap {
    padding: 15px;
  }

  .num {
    color: red;
    padding-right: 10px;
  }

  .waybill-list {
    // padding: 15px;

    .ant-card-bordered {
      border-radius: 5px;
    }

    .state {
      padding-top: 10px;
      padding-bottom: 8px;
    }

    .time {
      padding-top: 5px;
      justify-content: space-between;

      .timeitem {
        color: #c8c9cc;
      }

      .price {
        color: #CF0022;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .waybill-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .city {
      font-size: 16px;
      font-weight: 600;
      line-height: 2;
    }

    .user {
      font-size: 14px;
      color: @text-color-secondary;
    }

    div {
      text-align: center;
    }
  }
</style>